import React from 'react';
import { graphql } from 'gatsby';
import { Accordion, AccordionItem, AccordionItemTitle, AccordionItemBody } from 'react-accessible-accordion';
import { MdAdd } from 'react-icons/md';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import CallToAction from '../components/CallToAction';

const Services = ({ data }) => (
  <Layout>
    <SEO
      meta_title={data.prismicServicesPage.data.meta_title}
      meta_description={data.prismicServicesPage.data.meta_description}
    />
    <Hero
      title={data.prismicServicesPage.data.hero_text.text}
      fluid={data.prismicServicesPage.data.hero_image.localFile.childImageSharp.fluid}
      overlay="bg-black opacity-50"
    />

    <section className="p-0 bg-primary">
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/2 p-4 sm:p-6 md:p-16 flex flex-wrap md:justify-end">
          <div className="w-full md:max-w-sm lg:max-w-md">
            <h2>{data.prismicServicesPage.data.subheading.text}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: data.prismicServicesPage.data.content.html,
              }}
              className="mb-8 text-white"
            />
          </div>
        </div>
        <BackgroundImage
          className="w-full md:w-1/2 p-16 bg-grey-darker hidden md:block"
          fluid={data.prismicServicesPage.data.image_services.localFile.childImageSharp.fluid}
        />
      </div>
    </section>

    <section className="bg-grey-darker text-grey-light">
      <div className="container">
        <div className="flex flex-wrap justify-center">
          <div className="w-full">
            <h2 className="text-center">Services</h2>
            <Accordion>
              {data.allPrismicService.edges.map(({ node }) => (
                <div key={node.id}>
                  <AccordionItem id={node.uid}>
                    <AccordionItemTitle>
                      {node.data.service_title.text}{' '}
                      <span>
                        <MdAdd />
                      </span>
                    </AccordionItemTitle>
                    <AccordionItemBody>
                      <div className="flex flex-wrap md:-mx-6">
                        <div className="w-full md:w-1/2 md:px-6">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: node.data.service_description.html,
                            }}
                            className="mb-8 text-white"
                          />
                        </div>
                        <div className="w-full md:w-1/2 md:px-6">
                          <Img
                            fluid={node.data.service_image.localFile.childImageSharp.fluid}
                            alt={node.data.service_title.text ? node.data.service_title.text : 'Service Image'}
                          />
                        </div>
                      </div>
                    </AccordionItemBody>
                  </AccordionItem>
                </div>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </section>

    <CallToAction />
  </Layout>
);

export default Services;

export const query = graphql`
  query servicesPage {
    prismicServicesPage {
      data {
        title {
          html
          text
        }
        subheading {
          html
          text
        }
        content {
          html
          text
        }
        hero_image {
          alt
          url
          localFile {
            id
            childImageSharp {
              fluid(maxWidth: 1600, quality: 80, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        hero_text {
          html
          text
        }
        image_services {
          alt
          url
          localFile {
            id
            childImageSharp {
              fluid(maxWidth: 800, quality: 80, cropFocus: NORTHWEST) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        meta_title
        meta_description
      }
    }
    allPrismicService(sort: { fields: data___service_order___text }) {
      edges {
        node {
          id
          uid
          data {
            service_title {
              html
              text
            }
            service_description {
              html
              text
            }
            service_image {
              alt
              localFile {
                id
                childImageSharp {
                  fluid(maxWidth: 600, maxHeight: 400, quality: 80, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
